import React from "react";
import { StyledSection, StyledSectionInner } from "../assets/styles/commonStyles";
import { GlobalStyle } from "../assets/styles/globalStyles";
import Layout from "../components/common/Layout";

const NotFoundPage = () => {
	return (
		<Layout>
			<main>
				<GlobalStyle />
				<StyledSection>
					<StyledSectionInner noPaddingTopBot={true}>
						<h1>¡Página no encontrada!</h1>
					</StyledSectionInner>
				</StyledSection>
			</main>
		</Layout>
	);
};

export default NotFoundPage;
